import React from "react";
import "./dropdown.css";
import { Image } from "../../../common/components/product/ImageKit";
import {Link} from 'react-router-dom'

export default function NavDropdown() {
  return (
    <div className="drop__me transition-transform !duration-500 -translate-y-[200%] absolute top-16 right-0 left-0 bg-black/90 shadow shadow-white/5 backdrop-blur-sm w-full h-[60dvh] overflow-hidden rounded-b-xl flex flex-col">
      <div className="flex lg:py-3 md:px-12 xl:px-16 justify-start w-max h-max mt-3 max-lg-mb-4">
        <h4 className=" text-blue-500 text-base font-light">
          Explore All Products
        </h4>
      </div>
      <div className={`flex lg:py-3 xl:p-5 justify-between items-center lg:-mt-5`}>
        <div className="w-9/12 flex flex-col gap-5 px-5 ">
          <div className="w-full flex items-start justify-between max-lg:gap-3">
            {/* Wearables/Cards */}
            <div className="flex flex-column gap-5">
              {/* Wearables */}
              <div className="relative">
                <h4 className="text-white font-light text-xl">Wearables</h4>
                <Link to='/products/store/Voyager' className="relative !w-full !text-base !font-extralight !text-white/40 !transition !hover:underline">
                  Voyager{" "}
                  <span className="text-[0.4rem] bg-blue-500 text-white !absolute w-5 h-3 p-1 !top-0 flex justify-center items-center rounded !-right-8">
                    New
                  </span>
                </Link>
              </div>
              {/* Cards */}
              <div className="flex flex-col gap-2">
                <h4 className="text-white font-light text-xl">Cards</h4>
                <Link to='/products/store/AXAO_Phanthom' className="relative !text-base !font-extralight !text-white/40 !transition !hover:underline">
                  AXAO Phantom Cards
                </Link>
                <Link to='/products/store/AXAO_Card' className="relative !text-base !font-extralight !text-white/40 !transition !hover:underline">
                  Digital Business Cards
                </Link>
              </div>
            </div>
            {/* Audio */}
            <div className="flex flex-col gap-5">

            <div className="flex flex-col gap-2">
              <h4 className="text-white font-light  text-xl">Audio</h4>
              <Link to='/products/buds/onyx-panther1' className="relative !text-base !font-extralight !text-white/40 !transition !hover:underline">
                Onyx Panther
              </Link>
              <Link to='/products/buds/onyx-panther' className="relative !text-base !font-extralight !text-white/40 transition hover:underline">
                Onyx Panther 2{" "}
                <span className="text-[0.4rem] bg-blue-500 text-white absolute w-5 h-3 p-1 top-0 flex justify-center items-center rounded right-0">
                  New
                </span>
              </Link>
              <Link to='#' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                Storm Bud Pro
                <span className="text-[0.4rem] bg-blue-500 text-white absolute w-5 h-3 p-1 top-0 flex justify-center items-center rounded -right-6">
                  New
                </span>
              </Link>
              <Link to='/products/buds/bud-display' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                Storm Mini Pro
                <span className="text-[0.4rem] bg-blue-500 text-white absolute w-5 h-3 p-1 top-0 flex justify-center items-center rounded -right-6">
                  New
                </span>
              </Link>
            </div>

            {/* Digital Wallet */}
            <div className="flex flex-col gap-2">
              <h4 className="text-white font-light  text-xl">Digital Wallet</h4>
              <Link to='#' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                Enterprise
                {/* <span className="text-[0.4rem] bg-blue-500 text-white absolute w-5 h-3 p-1 top-0 flex justify-center items-center rounded right-0">
                  New
                </span> */}
              </Link>
              <Link to='#' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                Personal
                {/* <span className="text-[0.4rem] bg-blue-500 text-white absolute w-5 h-3 p-1 top-0 flex justify-center items-center rounded -right-6">
                  New
                </span> */}
              </Link>
            </div>
            </div>
            {/* Accessories/LifeStyle */}
            <div className="flex flex-col gap-5">
              {/* Accessories*/}
              <div className="flex flex-col gap-2">
                <h4 className="text-white font-light text-xl"> Accessories</h4>
                <Link to='#' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                  Ganwatt
                </Link>
                <Link to='#' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                  C To C Cable
                </Link>
                <Link to='#' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                  C To Lightening Cable
                </Link>
              </div>
              {/* Life Style*/}
              <div className="flex flex-col gap-2">
                <h4 className="text-white font-light text-xl">Life Style</h4>
                <a href='/products/store/MagSafe_Wallet' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                  Smart MagSafe Wallet
                </a>
                <a href='/products/wallet/#hive' className="relative !text-base !font-extralight text-white/40 transition hover:underline">
                  AXAO Hive
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-3/12 max-w-[250px] max-h-[400px] xl:h-full">
          <Image
            path="onyx2-bud.svg"
            loading="lazy"
            className="object-cover w-auto rounded-xl"
          />
        </div>
      </div>
    </div>
  );
}