import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PropTypes from "prop-types";
import PriceSlider from "./PriceSlider";

const FilterSection = ({ setQuery, page, itemsPerPage, prices }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [price, setPrice] = useState(false);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  
  useEffect(() => {
    const queryParams = [];
  
    // Add categories to query if selected
    if (selectedCategories.length > 0) {
      queryParams.push(`category=${selectedCategories.join("&category=")}`);
    }
  
    // Add minPrice or maxPrice to query if valid
    if (minPrice && !isNaN(minPrice)) {
      queryParams.push(`minPrice=${minPrice}`);
    }
  
    if (maxPrice && !isNaN(maxPrice)) {
      queryParams.push(`maxPrice=${maxPrice}`);
    }
  
    // Construct the query string
    const queryString = `/product?limit=${page}&offset=${itemsPerPage}${
      queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
    }`;
  
    setQuery(queryString);
  }, [selectedCategories, minPrice, maxPrice, page, itemsPerPage]);
  
 
  const handleChangeLabel = (label) => {
    if (label === "price filter"){
      setPrice(prev => !prev)
    } 
  
    setSelectedCategories((prev) =>
      prev.includes(label)
        ? // Remove the label if it's already in the array
          prev.filter((category) => category !== label)
        : // Add the label if it's not already in the array
          [...prev, label]
    );
  };
  

  return (
    <section className="h-auto w-3/12 bg-transparent px-4 max-lg:hidden">
      <div className="text-white border-b border-solid border-white/25 pb-12">
        <h1 className="font-light text-6xl">Filter</h1>
        {['price', 'category', 'discount'].map(item => <p key={item} className="font-extralight text-[1.25rem] capitalize">{item}</p>)}
      </div>
      <div>
        <FormGroup className="mt-4 tracking-tighter">
          {["wearables", "audio", "accessories", "cards", "discount", 'price filter'].map(
            (label) => (
              <FormControlLabel
                key={label}
                className="custom-label space-y-1.5"
                control={
                  <Checkbox
                    checked={selectedCategories.includes(label) || label==='price filter' }
                    onChange={() => handleChangeLabel(label)}
                    sx={{
                      color: "rgb(255 255 255 / 0.4)",
                      borderWidth:'.4px',
                      "& .MuiSvgIcon-root": { color: "white",fontSize:'1.2rem' }
                    }}
                    className="custom-checked space-y-1.5 mr-2 opacity-60"
                  />
                }
                label={label}
              />
            )
          )}
        </FormGroup>
      {true &&  <PriceSlider prices={prices}  min={setMinPrice} max={setMaxPrice}/>}
      </div>
    </section>
  );
};

FilterSection.propTypes = {
  setQuery: PropTypes.func,
  page: PropTypes.number,
  itemsPerPage: PropTypes.number,
  prices: PropTypes.array
};

export default FilterSection;
