import React, { useState } from "react";
import PropTypes from "prop-types";
import { IKContext, IKImage, IKVideo } from "imagekitio-react";

const urlEndpoint = "https://ik.imagekit.io/Adetimmy/";
const transform = [
  {
    height: "300",
    width: "500",
  },
];

export const Image = (props) => {
  return (
    <IKContext
      publicKey="public_YgXrMRmKMci3MDsWuZd/El7ephs="
      urlEndpoint={urlEndpoint}
    >
      <IKImage {...props} lqip={[{ active: true }]} loading="lazy" />
    </IKContext>
  );
};

Image.propTypes = {
  props: PropTypes.any,
};

export const ImageWithLoader = React.forwardRef(function ImageWithLoader({alt, Loader, ...rest}, ref) {
  // Track the loading state and error state
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  // Handle image loading
  const handleLoad = () => {
    setIsLoading(false); // Image loaded successfully
  };

  // Handle image error (e.g., if it fails to fetch)
  const handleError = () => {
    setIsLoading(false); // Stop showing loader
    setHasError(true); // Set error to true
  };

  return (
    <React.Fragment>
      {/* Show the loader if the image is still loading */}
      {isLoading && !hasError && (
        <div className="w-full h-full flex justify-center items-center ">
        {  Loader? <Loader/> : <div className="form-loading" />}
        </div>
      )}

      {/* Show the image if it has loaded, else show the alt text */}
      {!hasError ? (
        <img
          ref={ref}
          {...rest}
          onLoad={handleLoad}
          onError={handleError}
          alt={alt}
          hidden={isLoading}
          // style={{ display: isLoading ? 'none' : 'block' }} // Hide image until it loads
        />
      ) : (
        <div className="error">{alt}</div> // Show alt text if error occurs
      )}
    </React.Fragment>
  );
});
ImageWithLoader.displayName = ImageWithLoader;

ImageWithLoader.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  Loader: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
export default ImageWithLoader;
