import React from 'react'
import PropTypes from 'prop-types'
import { TiArrowSortedDown } from 'react-icons/ti';
import cartIcon from "../../images/cartIcon.svg";


function ProductPrice({ price, discount, discountedAmount }) {
    return (
      <div className="mt-3 mb-2 max-md:mb-3 flex justify-between items-center">
        <div className="flex items-center border relative border-white/30 border-solid px-1.5 py-2 rounded-lg">
          <img src={cartIcon} alt="cart icon" className="w-6 absolute left-3 top-2 transition-all duration-1000 ease-in-out opacity-0" />
          <span className="price font-extralight transition-all duration-1000 ease-in-out">{discountedAmount}</span>
        </div>
        <div className="flex flex-col items-start">
          <div className="line-through text-white font-extralight text-[20px]">
            {price}
          </div>
          <div className="flex gap-3 text-[#87B8F0] text-xs">
            <span>Save</span>
            <span className="hidden sm:flex items-center">
              <TiArrowSortedDown /> {discount}%
            </span>
          </div>
        </div>
      </div>
    );
  }

ProductPrice.propTypes = {
    price: PropTypes.any, 
    discount: PropTypes.any, 
    discountedAmount: PropTypes.any
}

export default ProductPrice
